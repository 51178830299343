import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import Layout from "../../components/layouts/layout"
import Seo from "../../components/global/Seo/seo"
import HeroBanner from "../../components/HeroBanner/HeroBanner"
//import EEContent from "../../components/EEContent/EEContent";
//import "./../../styles/articles.scss"

export const query = graphql`
  query StoryArticleQuery($uid: String!) {
    prismicCvswapoutStoryDetails(uid: { eq: $uid }) {
      id
      uid
      data {
        seo_metadata {
          document {
            ...seoData
          }
        }
        hero_banner {
          document {
            ...heroBannerData
          }
        }
        name
        title
        full_name
        age
        occupation
        content {
          html
          richText
        }
        story_image {
          alt
          gatsbyImageData
        }
        previous_story {
          id
          uid
        }
        next_story {
          id
          uid
        }
      }
    }
  }
`

const StoryArticlePage = ({ data }) => {
  if (!data.prismicCvswapoutStoryDetails?.data) return null

  const storyImage = getImage(
    data.prismicCvswapoutStoryDetails.data.story_image
  )
  const nextStoryLink = data.prismicCvswapoutStoryDetails.data.next_story.uid
  const previouStoryLink =
    data.prismicCvswapoutStoryDetails.data.previous_story.uid

  return (
    <Layout>
      <Seo
        data={data.prismicCvswapoutStoryDetails.data.seo_metadata.document.data}
      />
      <HeroBanner
        data={data.prismicCvswapoutStoryDetails.data.hero_banner.document.data}
      />
      <article className="article">
        <div className="container">
          <div className="row article-header">
            <div className="col">
              <h1 className="page-heading">
                {data.prismicCvswapoutStoryDetails.data.title}
              </h1>
            </div>
          </div>
          <div className="row article-body pt-5">
            <div className="col col-3">
              <div>
                {previouStoryLink && (
                  <Link
                    to={`/why-it-matters/`}
                    className="link mb-4 d-block"
                  >
                    <StaticImage src="./../../images/arrow-left.png" alt="Return to accounts" className="arrow-left" />
                      {`Return to accounts`}
                    </Link>
                )}
              </div>
              <div className="article-image">
                <GatsbyImage
                  image={storyImage}
                  alt={
                    data.prismicCvswapoutStoryDetails.data.story_image.alt
                      ? data.prismicCvswapoutStoryDetails.data.story_image.alt
                      : ""
                  }
                />
              </div>
            </div>
            <div className="col col-12 col-md-9 article-content">
              <h4 className="name mb-2">
                {data.prismicCvswapoutStoryDetails.data.full_name}
              </h4>
              <p>
                <b>Age :</b>
                {data.prismicCvswapoutStoryDetails.data.age}
              </p>
              <p>
                <b>Occupation :</b>
                {data.prismicCvswapoutStoryDetails.data.occupation}
              </p>
              <PrismicRichText
                field={data.prismicCvswapoutStoryDetails.data.content.richText}
              />
            </div>
          </div>
          <div className="row">
            <div className="col my-5">
              {previouStoryLink && (
                <Link
                  to={`/why-it-matters/${previouStoryLink}`}
                  className="link float-start"
                >
                  <StaticImage src="./../../images/arrow-left.png" alt="Back" className="arrow-left" />
                  {`Back`}</Link>
              )}
              {nextStoryLink && (
                <Link
                  to={`/why-it-matters/${nextStoryLink}`}
                  className="link float-end"
                >{`Next`}
                <StaticImage src="./../../images/arrow-right.png" alt="Next" className="arrow-right" /></Link>
              )}
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default StoryArticlePage
